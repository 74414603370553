import React from 'react';
import PropTypes from 'prop-types';
import PokerCardsFeature from "../../PokerCards";
import CounterButtonsProvider from "../../CounterButtons";
import {Box, Grid, Typography} from "@material-ui/core";
import Progress from "./Progress";

const PokerCards = ({ workoutGroup, groupID, workoutPartID, config, controlls, small, type }) => {
    const workoutPath = `/workout/${workoutGroup}/${groupID}/${workoutPartID}`
    const percentage = 0

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                        <Progress
                            percentage={percentage}
                            type={type}
                        >
                            <Box component={'span'}  display={'block'}>
                                <PokerCardsFeature
                                    gamePath={workoutPath}
                                    config={config}
                                    controlls={controlls}
                                    small
                                />
                            </Box>
                        </Progress>
                </Grid>
            </Grid>
        </>
    );
}

PokerCards.propTypes = {}

export default PokerCards;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { useNotification, useNotificationRemove } from './SnackbarContext'

const Alert = props => (
  <MuiAlert elevation={6} variant="filled" {...props} />
)

const Snackbar = ({ content }) => {
  const [open, setOpen] = useState(false)

  const notification = useNotification()
  const removeNotification = useNotificationRemove()
  const notificationRef = useRef()

  useEffect(() => {
    setOpen(true)
    notificationRef.current = notification
  }, [notification])

  if (!notification) {
    return null
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false)
    setTimeout(() => removeNotification(), 500)
  };

  const getDuration = () => {
    switch (notification.type) {
      case 'success': return 2000
      case 'warning': return 5000
      case 'error': return 8000
      default: return 4000
    }
  }

  return (
    <MuiSnackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={getDuration()} onClose={handleClose}>
      <Alert onClose={handleClose} severity={notification.type}>
        {notification.content.replace('[GraphQL] ', '')}
      </Alert>
    </MuiSnackbar>
  );
}

Snackbar.propTypes = {
  content: PropTypes.string,
}

export default Snackbar;

import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const NotificationContext = createContext();
const NotificationPushContext = createContext();
const NotificationRemoveContext = createContext();

export const useNotification = () => useContext(NotificationContext)
export const useNotificationPush = () => useContext(NotificationPushContext)
export const useNotificationRemove = () => useContext(NotificationRemoveContext)

const NotificationContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([])

  /**
   *
   * @param message - notification message
   * @param type - one of ['success', 'warning', 'info, 'error']
   */
  const pushNotification = ({ message, type }) =>
    setNotifications(prev => [...prev, { content: message, type }])

  const pushSuccess = message => pushNotification({ message, type: 'success'})
  const pushWarning = message => pushNotification({ message, type: 'warning'})
  const pushInfo = message => pushNotification({ message, type: 'info'})
  const pushError = message => pushNotification({ message, type: 'error'})

  /**
   * wrapper method to remove the current notification
   */
  const removeNotification = () =>
    setNotifications(prev => {
      const newNotifications = [...prev]
      newNotifications.shift()
      return newNotifications
    })

  /**
   * only recalculate the current notification when push or remove function was called
   */
  const getNotification = useMemo(() => {
    if (notifications.length) {
      return notifications[0]
    }

    return null
  }, [pushNotification, removeNotification])

  return (
    <NotificationContext.Provider value={getNotification}>
      <NotificationPushContext.Provider value={{
        pushSuccess,
        pushWarning,
        pushInfo,
        pushError,
      }}>
        <NotificationRemoveContext.Provider value={removeNotification}>
          {children}
        </NotificationRemoveContext.Provider>
      </NotificationPushContext.Provider>
    </NotificationContext.Provider>
  );
}

NotificationContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default NotificationContextProvider

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Counter from './components/Counter'
import {Accordion, AccordionDetails, AccordionSummary, Paper, Typography} from "@material-ui/core";
import {useStyles} from "./styles";
import WorkoutPart from "./WorkoutPart";
import theme from "../../layout/theme";

const WorkoutPartProvider = ({ config, workoutGroup, groupID, accordion }) => {
    const [expanded, setExpanded] = useState(false)

    if (!accordion) {
        return (
            <Paper style={{padding: theme.spacing(3)}}>
                <Typography variant={'h3'}>{config.Title}</Typography>
                <WorkoutPart
                    config={config}
                    workoutGroup={workoutGroup}
                    groupID={groupID}
                />
            </Paper>
        )
    }

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(prev => !prev)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
            >
                <Typography variant={'body1'}>{config.Title} </Typography>
                <Typography variant={'body2'} style={{marginLeft: 15}}> {config.Subtitle}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {expanded && (
                    <WorkoutPart
                        config={config}
                        workoutGroup={workoutGroup}
                        groupID={groupID}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    )
}

WorkoutPartProvider.propTypes = {}

export default WorkoutPartProvider;

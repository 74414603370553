import React from 'react';
import PropTypes from 'prop-types';

const Timer = ({ time, isCountdown }) => {
    return isCountdown ? (
        <span style={{
            background: 'red',
            padding: '0 20px',
            color: '#fff',
            borderRadius: '10px'
        }}>
            {time}
        </span>
    ) : time
}

Timer.propTypes = {}

export default Timer;

import React, {useState, useEffect, useRef} from 'react'
import moment from 'moment';
import db from '../../firebaseconfig'
import {useName} from "../../GroupsContext";
import Timer from "./Timer";
import TimerControlls from "./TimerControlls";
import {Grid, Typography} from "@material-ui/core";

Number.prototype.pad = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) {
        s = "0" + s;
    }
    return s;
}

const TimerProvider = ({path, display, groupTimer, otherGroups, controlls, exportValue}) => {
    const [isPlaying, setPlaying] = useState(false)
    const [isCountdown, setCountdown] = useState(false)
    const [time, setTime] = useState(0)
    const [timeOutput, setTimeOutput] = useState(0)
    const [groupTimeOutput, setGroupTimeOutput] = useState({})
    const [startTime, setStartTime] = useState(0)
    const [latenz, setLatenz] = useState(0);
    const groupTimeRef = useRef({});
    const {name} = useName()

    const timer = db.ref(`${path}/timer`)
    const offsetRef = db.ref(".info/serverTimeOffset");
    offsetRef.on("value", function (snap) {
        if (latenz !== snap.val()) {
            setLatenz(snap.val())
        }
    });

    const updateTime = () => {
        let countdownValue
        let totalSeconds = 0
        if (isPlaying) {
            const diff = moment(moment().add(latenz, 'ms').diff(moment(startTime, 'x'))).add(time).utc()
            countdownValue = Math.floor(moment.duration(diff.format('x')).asSeconds(), 10)
            if (countdownValue >= 0) {
                const hours = moment.duration(diff.format('x')).hours()
                const minutes = moment.duration(diff.format('x')).minutes()
                const seconds = moment.duration(diff.format('x')).seconds()
                totalSeconds = Math.floor(moment.duration(diff.format('x')).asSeconds())
                setTimeOutput(`${(hours.valueOf() * 60 + minutes.valueOf()).pad(2)}:${seconds.valueOf().pad(2)}`)
            } else {
                setTimeOutput(Math.abs(countdownValue))
            }
        } else {
            countdownValue = Math.floor(moment.duration(time?.valueOf() || 0).asSeconds(), 10)
            if (countdownValue >= 0) {
                const hours = moment.duration(time?.valueOf() || 0).hours()
                const minutes = moment.duration(time?.valueOf() || 0).minutes()
                const seconds = moment.duration(time?.valueOf() || 0).seconds()
                totalSeconds = Math.floor(moment.duration(time?.valueOf() || 0).asSeconds())
                setTimeOutput(`${(hours.valueOf() * 60 + minutes.valueOf()).pad(2)}:${seconds.valueOf().pad(2)}`)
            } else {
                setTimeOutput(Math.abs(countdownValue))
            }
        }
        if (isCountdown !== countdownValue < 0) {
            setCountdown(countdownValue < 0)
        }

        if (exportValue) {
            exportValue(totalSeconds)
        }
    }

    const updateGroupTime = () => {
        if (!groupTimer) {
            return null
        }

        const timerNames = Object.keys(groupTimeRef.current)
        let groupTotalTime = 0
        let isGlobalPlaying = false
        timerNames.forEach(t => {
            const isSinglePlaying = groupTimeRef.current[t].startTime > 0
            isGlobalPlaying = isGlobalPlaying || isSinglePlaying
            const localTimer = Math.floor(
                isSinglePlaying
                    ? moment.duration(
                    moment(moment().add(latenz, 'ms').diff(moment(groupTimeRef.current[t].startTime, 'x'))).add(groupTimeRef.current[t].time).utc().format('x')
                    ).asSeconds()
                    : moment.duration(groupTimeRef.current[t].time).asSeconds()
            )

            if (otherGroups) {
                setGroupTimeOutput(
                    prev => ({...prev, [t]: moment().startOf('day').second(localTimer).format('mm:ss')})
                )
            }
            groupTotalTime += (localTimer * 1000)
        })

        if (isPlaying !== isGlobalPlaying) {
            setPlaying(isGlobalPlaying)
        }

        const hours = moment.duration(groupTotalTime).hours()
        const minutes = moment.duration(groupTotalTime).minutes()
        const seconds = moment.duration(groupTotalTime).seconds()
        setTimeOutput(`${(hours.valueOf() * 60 + minutes.valueOf()).pad(2)}:${seconds.valueOf().pad(2)}`)
        if (exportValue) {
            exportValue(Math.floor(moment.duration(groupTotalTime).asSeconds()))
        }
    }

    const updateFromDBTimer = (data, name) => {
        if (data) {
            const key = Object.keys(data)[0]
            const newTimerValue = data[key];

            setStartTime(newTimerValue.startTime)
            setTime(newTimerValue.time)
            setPlaying(newTimerValue.startTime !== 0)

        } else {
            setStartTime(0)
            setTime(0)
            setPlaying(false)
        }
    }

    useEffect(() => {
        if (!groupTimer) {
            updateTime()
        }
    }, [isPlaying])

    useEffect(() => {
        if (groupTimer) {
            updateGroupTime()
        }
    }, [isPlaying])

    useEffect(() => {
        if (!isPlaying && !groupTimer) {
            updateTime()
        }
    }, [time])

    useEffect(() => {
        let timerInterval
        if (isPlaying) {
            timerInterval = setInterval(groupTimer ? updateGroupTime : updateTime, 100)
        } else {
            clearInterval(timerInterval)
        }

        return () => clearInterval(timerInterval)
    }, [startTime, isPlaying])

    useEffect(() => {
        const listener = timer.limitToLast(1).on('value', (snapshot) => {
            if (groupTimer) {
                const groups = snapshot.val() ? snapshot.val().group : false
                if (groups) {
                    const names = Object.keys(groups)
                    names.forEach(timerName => {
                        const userTimer = groups[timerName].timer
                        const keys = Object.keys(userTimer)
                        const lastKey = keys[keys.length - 1]
                        const el = keys.length ? userTimer[lastKey] : {}
                        groupTimeRef.current = {...groupTimeRef.current, [timerName]: el}
                    })
                    updateGroupTime()
                } else {
                    groupTimeRef.current = {}
                }
            } else {
                updateFromDBTimer(snapshot.val(), name);
            }
        });

        return () => timer.off('value', listener);
    }, [path])

    const names = Object.keys(groupTimeOutput)

    return (
        <>
            {display && (
                <Timer
                    time={timeOutput}
                    isCountdown={isCountdown}
                />
            )}
            {controlls && (
                <TimerControlls
                    controllsPath={path}
                    isPlaying={isPlaying}
                    time={time}
                    startTime={startTime}
                    latenz={latenz}
                />
            )}
            {otherGroups && names.map(n => n !== name && (
                <Typography><strong>{n}:</strong> {groupTimeOutput[n]}</Typography>
            ))}
        </>
    );
}

export default TimerProvider;

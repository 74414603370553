import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, CircularProgress, Grid, Typography} from "@material-ui/core";
import Club from "./icons/Club";
import Diamond from "./icons/Diamond";
import Heart from "./icons/Heart";
import Spades from "./icons/Spades";
import theme from "../../layout/theme";
import {Skeleton} from "@material-ui/lab";

const PokerCards = ({startTime, card, startGame, nextCard, totalTime, isLoading, controlls, small}) => {
    if (!startTime) {
        if (!controlls) {
            return null
        }
        return <Button
            onClick={startGame}
            variant={"outlined"}
            size={"large"}
            color={"primary"}
            fullWidth
        >
            Spiel starten
        </Button>
    }

    if (!card) {
        return (
            <>
                {controlls && <Typography align={"center"} variant={"h1"}>Geschafft</Typography>}
                <Typography align={"center"} variant={small ? 'h4' : 'h2'}>Zeit: {totalTime}</Typography>
                {controlls && (
                    <Button
                        onClick={startGame}
                        variant={"outlined"}
                        size={"large"}
                        color={"primary"}
                        fullWidth
                    >
                        Neues Spiel starten
                    </Button>
                )}
            </>
        )
    }

    const icon = (color) => {
        switch (card.Color) {
            case 'heart':
                return <Heart color={color}/>
            case 'spades':
                return <Spades color={color}/>
            case 'club':
                return <Club color={color}/>
            case 'diamonds':
                return <Diamond color={color}/>
            default:
                return <></>
        }
    }

    const color = isLoading ? theme.palette.primary.main : ['heart', 'diamonds'].includes(card.Color) ? 'red' : 'black'

    return (
        <>
            <Grid container justify={"center"} spacing={4} component={'span'} style={{maxWidth: small ? 160 : 'auto', marginLeft: small ? 'auto' : null}}>
                <Grid item xs={10} md={6} component={'span'}>
                    <Box
                        component={'span'}
                        display={'block'}
                        position={'relative'}
                        width={'100%'}
                        height={0}
                        paddingBottom={'150%'}
                        overflow={'hidden'}
                        style={{
                            border: `${small ? '2px' : '4px'} solid ${color}`,
                            borderRadius: small ? '10px' : '20px',
                            color: color,
                            boxShadow: theme.shadows[24]
                        }}
                    >
                        <Box
                            component={'span'}
                            display={'block'}
                            position={'absolute'}
                            bottom={small ? '20%' : '12%'}
                            right={small ? '30%' : '16%'}
                            style={{transform: 'translate(50%, 50%)'}}
                        >
                            {!isLoading && (
                                <Typography style={{
                                    fontSize: small ? 15 : 50
                                }} component={'span'}>{card.Number}</Typography>
                            )}
                        </Box>
                        <Box
                            component={'span'}
                            display={'block'}
                            position={'absolute'}
                            top={'50%'}
                            left={'50%'}
                            width={small ? 15 : 120}
                            height={small ? 15 : 120}
                            style={{transform: 'translate(-50%, -50%)'}}
                        >
                            {isLoading ? (
                                controlls && (
                                    <CircularProgress style={{
                                        width: '100%',
                                    }}/>
                                )
                            ) : icon(color)}
                        </Box>
                        <Box
                            component={'span'}
                            display={'block'}
                            position={'absolute'}
                            top={small ? '20%' : '12%'}
                            left={small ? '30%' : '16%'}
                            style={{transform: 'translate(-50%, -50%)'}}
                        >
                            {!isLoading && (
                                <Typography style={{
                                    fontSize: small ? 15 : 50
                                }} component={'span'}>{card.Number}</Typography>
                            )}
                        </Box>
                    </Box>
                </Grid>
                {controlls && (
                    <Grid item xs={10} md={8}>
                        <Typography align={"center"} variant={"h1"}>
                            {!isLoading
                                ? `${card.Amount} ${card.Exercise}`
                                : <Skeleton height={40}/>
                            }
                        </Typography>
                        <Button
                            fullWidth
                            variant={"contained"}
                            color={"primary"}
                            size={"large"}
                            onClick={nextCard}
                        >
                            Und weiter geht's... :-)
                        </Button>
                    </Grid>
                )}
            </Grid>
        </>
    );
}

PokerCards.propTypes =
{
}

export default PokerCards;

import React from 'react';
import PropTypes from 'prop-types';
import WorkoutPart from "../WorkoutPart";
import {Typography} from "@material-ui/core";

const Workout = ({workoutGroup, groupID, groupName, workoutConfig}) => {
    return (
        <>
            <Typography variant={'h1'}>{groupName}</Typography>
            {workoutConfig.map(config => (
                <WorkoutPart
                    accordion={workoutConfig.length > 1}
                    key={config.ID}
                    config={config}
                    workoutGroup={workoutGroup}
                    groupID={groupID}
                />
            ))}
        </>
    );
}

Workout.propTypes = {}

export default Workout;

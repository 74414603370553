import React from 'react';
import PropTypes from 'prop-types';

import db from '../../firebaseconfig'
import CounterButtons from './CounterButtons';
import {useName} from "../../GroupsContext";

const CounterButtonsProvider = ({ workoutPath, amount }) => {
    const { name } = useName()
    const workout = db.ref(`${workoutPath}/counter`)
    const addIterations = (count) => {
        workout.push({
            Count: amount,
            Name: name
        })
    }

    const removeIteration = () => {
        let removedItems = amount;
        const workout = db.ref(`${workoutPath}/counter`)
            .orderByChild('Name')
            .equalTo(name)
            .get().then(snap => {
                if (snap.val()) {
                    let entries = Object.entries(snap.val())
                    const filteredEntries = entries.filter(item => {
                        return item[1].Name === name
                    })

                    for (let i = 0; i < amount; i++) {
                        if (filteredEntries[i] && removedItems > 0) {
                            if (parseInt(filteredEntries[i][1].Count, 10) < removedItems) {
                                db.ref(`${workoutPath}/counter/${filteredEntries[i][0]}`)
                                    .remove()
                                removedItems = parseInt(removedItems, 10) - parseInt(filteredEntries[i][1].Count, 10)
                            } else {
                                const newValue = parseInt(filteredEntries[i][1].Count, 10) - parseInt(removedItems, 10);
                                if (newValue !== 0) {
                                    db.ref(`${workoutPath}/counter/${filteredEntries[i][0]}`)
                                        .update({
                                            Count: parseInt(filteredEntries[i][1].Count, 10) - parseInt(removedItems, 10)
                                        })
                                } else {
                                    db.ref(`${workoutPath}/counter/${filteredEntries[i][0]}`)
                                        .remove()
                                }
                                removedItems = parseInt(removedItems, 10) - parseInt(filteredEntries[i][1].Count, 10)
                            }

                        }
                    }
                }
            })
    }

    return (
        <>
            <CounterButtons
                addIterations={addIterations}
                removeIteration={removeIteration}
                amount={amount}
            />
        </>
    );
}

CounterButtonsProvider.propTypes = {}

export default CounterButtonsProvider;

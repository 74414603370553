import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import db from '../../firebaseconfig'

const CounterTriathlonProvider = ({ workoutPath, exportValue, type }) => {
    const [counter, setCounterTriathlon] = useState(0);
    const [names, setNames] = useState([]);
    const workout = db.ref(`${workoutPath}/counterTriathlon`)

    useEffect(() => {
        if (exportValue) {
            exportValue(counter)
        }
    }, [counter])

    const convertData = data => {
        let count = 0;
        let localNames = [];

        if (data) {
            Object.keys(data).forEach(key => {
                if (data[key].Count && (data[key].Type === type || (type === 'RunBike' && data[key].Type !== 'Swim'))) {
                    count = count + parseInt(data[key].Count, 10);
                    if (localNames[data[key].Name]) {
                        localNames[data[key].Name] = parseInt(localNames[data[key].Name], 10) + parseInt(data[key].Count, 10);
                    } else {
                        localNames[data[key].Name] = parseInt(data[key].Count, 10);
                    }
                }
            })
        }

        if (count != counter) {
            setNames(localNames)
            setCounterTriathlon(count);
        }
    }

    workout.on('value', (snapshot) => {
        convertData(snapshot.val())
    });

    return type ? counter : ''
}

CounterTriathlonProvider.propTypes = {}

export default CounterTriathlonProvider;

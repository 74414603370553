import React from 'react';
import PropTypes from 'prop-types';
import Workout from "../features/Workout";
import {Grid} from "@material-ui/core";

const WorkoutPage = ({workoutGroup, groupID}) => {
    return (
        <Grid container justify={"center"}>
            <Grid item xs={12} md={10}>
                <Workout
                    workoutGroup={workoutGroup}
                    groupID={groupID}
                />
            </Grid>
        </Grid>
    );
}

WorkoutPage.propTypes = {}

export default WorkoutPage;

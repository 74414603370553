import React, {useState} from 'react';
import PropTypes from 'prop-types';
import WorkoutEdit from "./WorkoutEdit";

import db from '../../firebaseconfig'
import EditItem from "./components/EditItem";
import {TextField} from "@material-ui/core";

const WorkoutEditProvider = ({ workoutGroup }) => {
    const [password, setPassword] = useState('')

    const config = db.ref(`/config/${workoutGroup}`)

    const updateTitle = (id, value) => {
        db.ref(`/config/${workoutGroup}/${id}`)
            .update({ Title: value })
    }

    const updateSubtitle = (id, value) => {
        db.ref(`/config/${workoutGroup}/${id}`)
            .update({ Subtitle: value })
    }

    const updateLimit = (id, value) => {
        db.ref(`/config/${workoutGroup}/${id}`)
            .update({ Limit: value })
    }

    const updateType = (id, value) => {
        db.ref(`/config/${workoutGroup}/${id}`)
            .update({ Type: value })
    }

    const addNewWorkout = () => {
        db.ref(`/config/${workoutGroup}`).push({
            Title: '',
            Subtitle: '',
            Limit: '',
            Type: 'Counter',
        })
    }

    const deleteWorkout = id => {
        db.ref(`/config/${workoutGroup}/${id}`).remove()
    }

    if (password !== 'BalticStroms!') {
        return (
            <TextField
                fullWidth
                label={'Passwort'}
                type={'password'}
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
        )
    }

    return (
        <>
            <WorkoutEdit
                workoutGroup={workoutGroup}
                updateTitle={updateTitle}
                updateSubtitle={updateSubtitle}
                updateLimit={updateLimit}
                updateType={updateType}
                addNewWorkout={addNewWorkout}
                deleteWorkout={deleteWorkout}
            />
        </>
    );
}

WorkoutEditProvider.propTypes = {}

export default WorkoutEditProvider;

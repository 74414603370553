import React, {useState} from 'react'
import PropTypes from 'prop-types';
import {useStyles} from './styles'
import {Container, createMuiTheme, CssBaseline} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles'

import LayoutHeader from './components/LayoutHeader'
import theme from './theme'
import EditName from "../features/EditName";
import EditGroupName from "../features/EditGroupName";
import {useName} from "../GroupsContext";

/**
 * Container component for Layout feature
 *
 * @param {*} props
 */
const Layout = ({ children, workoutGroup, groupID }) => {
    const classes = useStyles();
    const [nameDialogOpen, setNameDialogOpen] = useState(false)
    const [groupDialogOpen, setGroupDialogOpen] = useState(false)
    const { name } = useName()

    console.log(workoutGroup)

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Container className={classes.wrapper}>
                <LayoutHeader
                    classes={classes}
                    workoutGroup={workoutGroup}
                    groupID={groupID}
                />
                <main className={classes.content}>
                    <div className={classes.toolbar}/>
                    <div className={classes.appBar}>
                        <EditName
                            open={nameDialogOpen || name === '' || !name}
                            setOpen={setNameDialogOpen}
                        />
                        {children}
                    </div>
                </main>
            </Container>
        </ThemeProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}


export default Layout;

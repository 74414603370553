import React, {useState} from 'react';
import PropTypes from 'prop-types';
import CounterFeature from "../../Counter";
import TimerFeature from "../../Timer";
import CounterButtonsProvider from "../../CounterButtons";
import {Grid, Typography} from "@material-ui/core";
import Progress from "./Progress";

const CounterCentralTimer = ({ workoutGroup, groupID, workoutPartID, config, type }) => {
    const [value, setValue] = useState(0)
    const workoutPath = `/workout/${workoutGroup}/${groupID}/${workoutPartID}`

    const percentage = config.Limit ? (value / config.Limit) * 100 : 0
    const exportValue = v => setValue(v)

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Progress
                        percentage={percentage}
                        type={type}
                    >
                        <CounterFeature
                            workoutPath={workoutPath}
                            exportValue={exportValue}
                        />
                    </Progress>
                </Grid>
            </Grid>
        </>
    );
}

CounterCentralTimer.propTypes = {}

export default CounterCentralTimer;

import React from 'react';
import PropTypes from 'prop-types';
import {Box, CircularProgress, Grid, LinearProgress, Typography} from "@material-ui/core";
import CounterFeature from "../../Counter";
import theme from "../../../layout/theme";

const Progress = ({children, percentage, width = 78, type = 'circle' }) => {

    if (type === 'bar') {
        return (
            <>
                <Typography variant={'h3'} component={'p'} align={"right"} style={{ marginBottom: 0 }}>
                    {children}
                </Typography>
                <LinearProgress
                    variant={"determinate"}
                    value={percentage <= 100 ? percentage : 100}
                    style={{width: '100%', height: '10px', margin: '10px 0'}}
                />
            </>
        )
    }

    return (
        <Box position="relative" display="inline-flex" style={{width: '100%'}}>
            <Box position="relative" display="inline-flex" style={{
                border: `1px solid ${theme.palette.primary.main}`, borderRadius: 100,
                margin: 'auto'
            }}>
                <CircularProgress
                    variant={"determinate"}
                    value={percentage <= 100 ? percentage : 100}
                    style={{ width: width, height: width }}
                />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant={'h3'} style={{ marginBottom: 0, textAlign: 'center' }}>
                        {children}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

Progress.propTypes = {}

export default Progress;

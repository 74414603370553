import React from 'react';
import PropTypes from 'prop-types';
import CounterFeature from "../../Counter";
import CounterButtonsProvider from "../../CounterButtons";
import {Grid, Typography} from "@material-ui/core";

const Counter = ({ workoutGroup, groupID, workoutPartID }) => {
    const workoutPath = `/workout/${workoutGroup}/${groupID}/${workoutPartID}`
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant={'h1'} align={"right"}>
                        <CounterFeature
                            workoutPath={workoutPath}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <CounterButtonsProvider workoutPath={workoutPath} amount={1}/>
                </Grid>
                <Grid item xs={12}>
                    <CounterButtonsProvider workoutPath={workoutPath} amount={5}/>
                </Grid>
                <Grid item xs={12}>
                    <CounterButtonsProvider workoutPath={workoutPath} amount={10}/>
                </Grid>
            </Grid>
        </>
    );
}

Counter.propTypes = {}

export default Counter;

export const drawerWidth = 240;

export const pokerCardNumbers = [
    '7',
    '8',
    '9',
    '10',
    'B',
    'D',
    'K',
    'A',
]

export const pokerCardColors = [
    'heart',
    'spades',
    'club',
    'diamonds',
]
export const zoomLink = 'https://us02web.zoom.us/j/89163923947?pwd=clc4T1VrY0tCZEJzbnhqaE1sVzBxdz09';

export const laufergebnisse = 'https://docs.google.com/spreadsheets/d/1SGYfXvcrlUHAfCPwowUQpjqf12GrC1zMg2VExLU0nuI/edit?usp=sharing';

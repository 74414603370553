import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import db, {dbTime} from "../../firebaseconfig";
import {Button} from "@material-ui/core";
import {pokerCardColors, pokerCardNumbers} from "../../config";
import PokerCards from "./PokerCards";
import moment from 'moment'

const shuffleArray = arr => arr
    .map(a => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map(a => a[1]);

const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
}

const PokerCardsProvider = ({ gamePath, config, controlls, small }) => {
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [card, setCard] = useState(null)
    const [isLoading, setLoading] = useState(true)

    const game = db.ref(`${gamePath}/poker`)

    const shuffleCards = () => {
        const configColors = config.Subtitle.split(',')
        const configNumbers = config.Limit.split(',')
        console.log(configColors, configNumbers)
        let cards = []
        pokerCardColors.forEach((color, colorIndex) => {
            pokerCardNumbers.forEach((num, numIndex) => {
                cards.push({
                    Color: color,
                    Number: num,
                    Exercise: configColors[colorIndex] || '',
                    Amount: configNumbers[numIndex] || ''
                })
            })
        })

        return shuffle(shuffle(cards))
    }

    const startGame = () => {
        game.remove()
        shuffleCards().forEach(card => {
            game.child('cards').push(card)
        })
        game.update({ startTime: dbTime })
    }

    const nextCard = () => {
        if (card) {
            game.child('cards').child(card.ID).remove().then(res => console.log(res))
        }
    }

    useEffect(() => {
        if (game) {
            const listener = game.on('value', item => {
                const data = item.val()
                console.log(data)
                const cards = data?.cards
                const keys = cards ? Object.keys(cards) : []
                setLoading(true)
                if (cards && keys.length) {
                    setCard({...cards[keys[0]], ID: keys[0]})
                    setTimeout(() => setLoading(false), 800)
                } else {
                    if (data && data.startTime && !data.endTime) {
                        game.update({ endTime: dbTime })
                    }
                    setCard(null)
                }
                if (data?.startTime) {
                    setStartTime(moment(data.startTime, 'x'))
                }
                if (data?.endTime) {
                    setEndTime(moment(data.endTime, 'x'))
                }
            })

            return () => game.off('value', listener);
        }
    }, [gamePath]);

    return (
        <>
            <PokerCards
                startTime={startTime}
                endTime={endTime}
                card={card}
                startGame={startGame}
                nextCard={nextCard}
                isLoading={isLoading}
                totalTime={endTime
                    ? `${moment.duration(endTime.diff(startTime)).minutes().valueOf().pad(2)}:${moment.duration(endTime.diff(startTime)).seconds().valueOf().pad(2)}`
                    : ''
                }
                controlls={controlls}
                small={small}
            />
        </>
    );
}

PokerCardsProvider.propTypes = {}

export default PokerCardsProvider;

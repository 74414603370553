import React from 'react';
import PropTypes from 'prop-types';
import {useDashboardType, useGroups, useWorkoutConfig} from "../../GroupsContext";
import DashboardItem from "./DashboardItem";
import {Grid, Typography} from "@material-ui/core";
import { navigate}  from 'hookrouter';

const Dashboard = ({ workoutGroup, group, groupID }) => {
    const workoutConfig = useWorkoutConfig()
    const {dashboardType} = useDashboardType()

    const openGroup = () => navigate(`/${workoutGroup}/${groupID}`)

    return (
        <Grid container spacing={1} justify={"space-between"} style={{ padding: '10px 25px' }}>
            <Grid item xs={12} onClick={openGroup} style={{cursor: 'pointer'}}>
                <Typography variant={'h3'}>{group.Name}</Typography>
            </Grid>
            {workoutConfig.map(config => (
                <Grid item xs={dashboardType === 'bar' ? 12 : null} md={dashboardType === 'bar' ? 12 : null} key={config.ID} style={{minWidth: 100}}>
                    <DashboardItem
                        config={config}
                        workoutGroup={workoutGroup}
                        groupID={groupID}
                        type={dashboardType}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

Dashboard.propTypes = {}

export default Dashboard;

import React, {useContext, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import db from "../../firebaseconfig";
import { navigate } from 'hookrouter';
import EditName from "../../features/EditName";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import WorkoutEdit from "../../features/WorkoutEdit";
import EditGroupName from "../../features/EditGroupName";

const AppBarMenu = ({ workoutGroup, groupID }) => {
    const [workoutDialogOpen, setWorkoutDialogOpen] = useState(false);
    const [nameDialogOpen, setNameDialogOpen] = useState(false);
    const [groupNameDialogOpen, setGroupNameDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                color={"inherit"}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: '20ch',
                    },
                }}
            >
                <MenuItem onClick={() => {
                    setNameDialogOpen(true);
                    setAnchorEl(null);
                }}>
                    Namen bearbeiten
                </MenuItem>
                <MenuItem onClick={() => {
                    setWorkoutDialogOpen(true);
                    setAnchorEl(null);
                }}>
                    Workout bearbeiten
                </MenuItem>
                {groupID && (
                    <MenuItem onClick={() => {
                        setGroupNameDialogOpen(true);
                        setAnchorEl(null);
                    }}>
                        Gruppennamen bearbeiten
                    </MenuItem>
                )}
                {groupID && (
                    <MenuItem onClick={() => {
                        db.ref(`groups/${workoutGroup}/${groupID}`)
                            .remove()
                        db.ref(`workout/${workoutGroup}/${groupID}`)
                            .remove()
                        navigate('/')
                        setAnchorEl(null);
                    }}>
                        Gruppe löschen
                    </MenuItem>
                )}
            </Menu>
            <EditName
                open={nameDialogOpen}
                setNameDialogOpen={setNameDialogOpen}
            />
            <EditGroupName
                open={groupNameDialogOpen}
                setDialogOpen={setGroupNameDialogOpen}
                workoutGroup={workoutGroup}
                groupID={groupID}
            />
            <Dialog open={workoutDialogOpen} fullWidth maxWidth={"md"} onClose={() => setWorkoutDialogOpen(false)}>
                <DialogTitle>Workout bearbeiten</DialogTitle>
                <DialogContent>
                    <WorkoutEdit workoutGroup={workoutGroup} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setWorkoutDialogOpen(false)}>Schließen</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AppBarMenu;

import React from 'react';
import PropTypes from 'prop-types';
import Counter from "./components/Counter";
import CounterCentralTimer from "./components/CounterCentralTimer";
import CounterTimer from "./components/CounterTimer";
import Timer from "./components/Timer";
import TimerGroup from "./components/TimerGroup";
import PokerCards from "./components/PokerCards";
import CounterTriathlon from "./components/CounterTriathlon";

const WorkoutPart = ({ config, workoutGroup, groupID }) => {
    switch (config.Type) {
        case 'Counter':
            return (
                <Counter
                    workoutGroup={workoutGroup}
                    groupID={groupID}
                    workoutPartID={config.ID}
                />
            )
        case 'CounterTriathlon':
            return (
                <CounterTriathlon
                    workoutGroup={workoutGroup}
                    groupID={groupID}
                    workoutPartID={config.ID}
                />
            )
        case 'CounterCentralTimer':
            return (
                <CounterCentralTimer
                    workoutGroup={workoutGroup}
                    groupID={groupID}
                    workoutPartID={config.ID}
                />
            )
        case 'CounterTimer':
            return (
                <CounterTimer
                    workoutGroup={workoutGroup}
                    groupID={groupID}
                    workoutPartID={config.ID}
                />
            )
        case 'Timer':
            return (
                <Timer
                    workoutGroup={workoutGroup}
                    groupID={groupID}
                    workoutPartID={config.ID}
                />
            )
        case 'TimerGroup':
            return (
                <TimerGroup
                    workoutGroup={workoutGroup}
                    groupID={groupID}
                    workoutPartID={config.ID}
                />
            )
        case 'PokerCards':
            return (
                <PokerCards
                    workoutGroup={workoutGroup}
                    groupID={groupID}
                    workoutPartID={config.ID}
                    config={config}
                />
            )
        default:
            return <>Typ nicht definiert</>
    }
}

WorkoutPart.propTypes = {}

export default WorkoutPart;

import React from 'react';
import PropTypes from 'prop-types';

const Diamond = ({ color }) => {
    return (
        <>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 223.646 223.646" style={{enableBackground: "new 0 0 223.646 223.646", fill: color}}>
                <polygon points="111.823,0 16.622,111.823 111.823,223.646 207.025,111.823 "/>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
            </svg>
        </>
    );
}

Diamond.propTypes =
{
}

export default Diamond;

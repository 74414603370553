import React, {useContext, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import db from "../../firebaseconfig";
import moment from "moment";

const EditGroupName = ({ open, setDialogOpen, workoutGroup, groupID }) => {
    const [localName, setLocalName] = React.useState('');

    const handleClose = () => {
        setDialogOpen(false)
    };

    const handleSave = () => {
        db.ref(`groups/${workoutGroup}/${groupID}`).update({
            Name: localName
        })
        setDialogOpen(false)
    }

    useEffect(() => {
        db.ref(`groups/${workoutGroup}/${groupID}`).get().then(snap => {
            if (snap.val() && snap.val().Name !== localName) {
                setLocalName(snap.val().Name)
            }
        })
    }, [groupID])

    if (!groupID || groupID === '') {
        return null;
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Gib deinen Gruppennamen ein</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Gruppenname"
                        value={localName}
                        type="text"
                        fullWidth
                        onChange={e => setLocalName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={localName === ''} onClick={handleSave} color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EditGroupName;

import firebase from "firebase";

const config = {
    apiKey: "AIzaSyDIxG1C-1znfBUOzX1DiohjOPd7pawmZLc",
    authDomain: "workout-tracker-d98c4.firebaseapp.com",
    databaseURL: "https://workout-tracker-d98c4-default-rtdb.firebaseio.com",
    storageBucket: "workout-tracker-d98c4.appspot.com",
    // messagingSenderId: "756267752855",
    // appId: "1:756267752855:web:3d85de3cad4993a31df8d2",
    // measurementId: "G-BY6HVWE6ZT"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

export default firebase.database();

export let dbTime = firebase.database.ServerValue.TIMESTAMP;

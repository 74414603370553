import React from 'react';
import PropTypes from 'prop-types';
import Dashboard from "./Dashboard";
import {useGroups, useWorkoutConfig} from "../../GroupsContext";
import {Grid, Paper} from "@material-ui/core";

const DashboardProvider = ({ workoutGroup }) => {
    const groups = useGroups()
    const workoutConfig = useWorkoutConfig()
    console.log(workoutConfig.length)
    return (
        <>
            <Grid container spacing={2} >
            {groups.map(group => (
                    <Grid item xs={12} sm={workoutConfig.length > 3 ? 12 : 6} key={group.key}>
                        <Paper>
                            <Dashboard
                                key={group.key}
                                group={group.value}
                                groupID={group.key}
                                workoutGroup={workoutGroup}
                            />
                        </Paper>
                    </Grid>
            ))}
                </Grid>
        </>
    );
}

DashboardProvider.propTypes = {}

export default DashboardProvider;

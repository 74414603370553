import { makeStyles } from '@material-ui/core/styles';

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles(theme => ({
    workoutPart: {
        padding: theme.spacing(4)
    },
}));

import React, {useContext, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useName} from "../../GroupsContext";

const EditName = ({ open, setNameDialogOpen }) => {
    const [localName, setLocalName] = React.useState('');
    const { setName } = useName()

    const handleClose = () => {
        window.localStorage.setItem('name', localName);
        setName(localName)
        if (setNameDialogOpen) {
            setNameDialogOpen(false)
        }
    };

    useEffect(() => {
        if (window.localStorage.getItem('name')) {
            setLocalName(window.localStorage.getItem('name'))
        }
    }, []);

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Gib deinen Namen ein</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Bitte gib deinen Namen ein, damit deine Einträge in Gruppenworkouts zugeordnet werden können:
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Vorname"
                    value={localName}
                    type="text"
                    fullWidth
                    onChange={e => setLocalName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={localName === ''} onClick={handleClose} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditName;

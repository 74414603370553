import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import db from "./firebaseconfig";

const DashboardContext = createContext();
const NameContext = createContext();
const GroupsContext = createContext();
const WorkoutConfigContext = createContext();

export const useDashboardType = () => useContext(DashboardContext)
export const useName = () => useContext(NameContext)
export const useGroups = () => useContext(GroupsContext)
export const useWorkoutConfig = () => useContext(WorkoutConfigContext)

const GroupsContextProvider = ({ workoutGroup, children }) => {
  const [dashboardType, setDashboardType] = useState(() => localStorage.getItem('dashboardType') || 'circle');
  const [name, setName] = useState(() => localStorage.getItem('name'));
  const [groups, setGroups] = useState([]);
  const [workoutConfig, setWorkoutConfig] = useState([]);

  const dbGroups = db.ref(`groups/${workoutGroup}`);
  const dbWorkoutConfig = db.ref(`config/${workoutGroup}`);

  dbGroups.on('value', (snapshot) =>{
    const data = snapshot.val();
    let list = [];

    if (data) {
      Object.keys(data).forEach(key => {
        list.push({
          'key': key,
          'value': data[key]
        });
      })
    }

    if (JSON.stringify(groups) != JSON.stringify(list)) {
      setGroups(list);
    }
  });

  dbWorkoutConfig.on('value', (snapshot) =>{
    const data = snapshot.val();
    let list = [];

    if (data) {
      Object.keys(data).forEach(key => {
        list.push({
          ID: key,
          Title: data[key].Title,
          Subtitle: data[key].Subtitle,
          Type: data[key].Type,
          Limit: data[key].Limit
        });
      })
    }
    if (JSON.stringify(workoutConfig) != JSON.stringify(list)) {
      setWorkoutConfig(list);
    }
  });

  console.log(workoutConfig)

  return (
    <GroupsContext.Provider value={groups}>
      <WorkoutConfigContext.Provider value={workoutConfig}>
        <NameContext.Provider value={{ name, setName }}>
          <DashboardContext.Provider value={{ dashboardType, setDashboardType }}>
            {children}
          </DashboardContext.Provider>
        </NameContext.Provider>
      </WorkoutConfigContext.Provider>
    </GroupsContext.Provider>
  );
}

GroupsContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default GroupsContextProvider

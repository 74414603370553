import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import db from '../../firebaseconfig'

const AddGroupProvider = ({ workoutGroup }) => {
    const [addGroup, setAddGroup] = useState('')

    const submitNewGroup = e => {
        e.preventDefault()
        const dbgroups = db.ref(`groups/${workoutGroup}`);
        dbgroups.push({
            Name: addGroup
        })
        setAddGroup('')
    }

    return (
        <form onSubmit={submitNewGroup}>
            <TextField
                fullWidth
                style={{display: 'block', width: '100%', marginBottom: 10}}
                value={addGroup}
                onChange={e => {setAddGroup(e.target.value)}}
                id="outlined-basic"
                label="Gruppe hinzufügen"
                variant="outlined"
            />
            <Button
                fullWidth
                color={'primary'}
                variant="contained"
                size="large"
                type={"submit"}
            >
                Hinzufügen
            </Button>
        </form>
    );
}

AddGroupProvider.propTypes = {}

export default AddGroupProvider;

import React from 'react';
import PropTypes from 'prop-types';
import CounterTriathlonFeature from "../../CounterTriathlon";
import CounterTriathlonButtonsProvider from "../../CounterTriathlonButtons";
import {Grid, Typography} from "@material-ui/core";
import PoolIcon from '@material-ui/icons/Pool';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import CounterTriathlonListProvider from "../../CounterTriathlonList";

const Counter = ({ workoutGroup, groupID, workoutPartID }) => {
    const workoutPath = `/workout/${workoutGroup}/${groupID}/${workoutPartID}`

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Typography variant={'h1'} align={"center"}>
                        <DirectionsRunIcon />/<DirectionsBikeIcon /> <CounterTriathlonFeature
                            workoutPath={workoutPath}
                            type={'RunBike'}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant={'h1'} align={"center"}>
                        <DirectionsRunIcon /> <CounterTriathlonFeature
                            workoutPath={workoutPath}
                            type={'Run'}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant={'h1'} align={"center"}>
                        <DirectionsBikeIcon /> <CounterTriathlonFeature
                            workoutPath={workoutPath}
                            type={'Bike'}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant={'h1'} align={"center"}>
                        <PoolIcon /> <CounterTriathlonFeature
                            workoutPath={workoutPath}
                            type={'Swim'}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <CounterTriathlonListProvider
                        workoutPath={workoutPath}
                        workoutGroup={workoutGroup}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CounterTriathlonButtonsProvider workoutGroup={workoutGroup} workoutPath={workoutPath} amount={1} type={'Run'}/>
                    <CounterTriathlonButtonsProvider workoutGroup={workoutGroup} workoutPath={workoutPath} amount={1} type={'Bike'}/>
                    <CounterTriathlonButtonsProvider workoutGroup={workoutGroup} workoutPath={workoutPath} amount={1} type={'Swim'}/>
                </Grid>
            </Grid>
        </>
    );
}

Counter.propTypes = {}

export default Counter;

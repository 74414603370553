import React from 'react';
import PropTypes from 'prop-types';
import {useWorkoutConfig} from "../../GroupsContext";
import EditItem from "./components/EditItem";
import {Button, Grid} from "@material-ui/core";

const WorkoutEdit = ({ workoutGroup, updateTitle, updateSubtitle, updateLimit, updateType, addNewWorkout, deleteWorkout }) => {

    const workoutConfig = useWorkoutConfig()

    return (
        <>
            <Grid container spacing={2}>
                {workoutConfig.map(config => (
                    <Grid item xs={12} key={config.ID}>
                        <EditItem
                            key={config.ID}
                            config={config}
                            workoutGroup={workoutGroup}
                            updateTitle={updateTitle}
                            updateSubtitle={updateSubtitle}
                            updateLimit={updateLimit}
                            updateType={updateType}
                            addNewWorkout={addNewWorkout}
                            deleteWorkout={deleteWorkout}
                        />
                    </Grid>
                ))}
            </Grid>
            <br />
            <Button
                fullWidth
                color={"primary"}
                variant={"contained"}
                onClick={addNewWorkout}
            >
                Workout hinzufügen
            </Button>
        </>
    );
}

WorkoutEdit.propTypes = {}

export default WorkoutEdit;

import React from 'react';
import PropTypes from 'prop-types';
import CounterFeature from "../../Counter";
import TimerFeature from "../../Timer";
import CounterButtonsProvider from "../../CounterButtons";
import {Grid, Typography} from "@material-ui/core";

const CounterTimer = ({ workoutGroup, groupID, workoutPartID }) => {
    const workoutPath = `/workout/${workoutGroup}/${groupID}/${workoutPartID}`
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h1'} align={"center"}>
                        <CounterFeature
                            workoutPath={workoutPath}
                        />
                    </Typography>
                    <Typography variant={'h2'} align={"center"}>
                        <TimerFeature
                            display
                            controlls
                            path={workoutPath}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <CounterButtonsProvider workoutPath={workoutPath} amount={1}/>
                </Grid>
                <Grid item xs={12}>
                    <CounterButtonsProvider workoutPath={workoutPath} amount={5}/>
                </Grid>
                <Grid item xs={12}>
                    <CounterButtonsProvider workoutPath={workoutPath} amount={10}/>
                </Grid>
            </Grid>
        </>
    );
}

CounterTimer.propTypes = {}

export default CounterTimer;

import React from 'react';
import PropTypes from 'prop-types';
import TimerFeature from  '../../Timer'
import {Typography} from "@material-ui/core";

const Timer = ({ workoutGroup, groupID, workoutPartID }) => {
    const timerPath = `/workout/${workoutGroup}/${groupID}/${workoutPartID}`
  return (
    <>
        <Typography variant={'h1'} align={"center"}>
            <TimerFeature
                display
                controlls
                path={timerPath}
            />
        </Typography>
    </>
  );
}

Timer.propTypes = {}

export default Timer;

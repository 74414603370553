import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../config'

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,

    },
    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(1),
    },
    title: {
        flexGrow: 1
    }
}));

import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from './Snackbar';
import SnackbarContext from './SnackbarContext';

const SnackbarProvider = ({ children }) => {
  return (
    <SnackbarContext>
      {children}
      <Snackbar />
    </SnackbarContext>
  );
}

SnackbarProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default SnackbarProvider;

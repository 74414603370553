import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import db from '../../firebaseconfig'

const CounterProvider = ({ workoutPath, exportValue }) => {
    const [counter, setCounter] = useState(0);
    const [names, setNames] = useState([]);
    const workout = db.ref(`${workoutPath}/counter`)

    useEffect(() => {
        if (exportValue) {
            exportValue(counter)
        }
    }, [counter])

    const convertData = data => {
        let count = 0;
        let localNames = [];

        if (data) {
            Object.keys(data).forEach(key => {
                if (data[key].Count) {
                    count = count + parseInt(data[key].Count, 10);
                    if (localNames[data[key].Name]) {
                        localNames[data[key].Name] = parseInt(localNames[data[key].Name], 10) + parseInt(data[key].Count, 10);
                    } else {
                        localNames[data[key].Name] = parseInt(data[key].Count, 10);
                    }
                }
            })
        }

        if (count != counter) {
            setNames(localNames)
            setCounter(count);
        }
    }

    workout.on('value', (snapshot) => {
        convertData(snapshot.val())
    });

    return counter
}

CounterProvider.propTypes = {}

export default CounterProvider;

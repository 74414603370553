import React, {useState} from 'react';
import PropTypes from 'prop-types';
import CounterTriathlonFeature from "../../CounterTriathlon";
import CounterButtonsProvider from "../../CounterButtons";
import {CircularProgress, Grid, LinearProgress, Typography} from "@material-ui/core";
import Progress from "./Progress";
import PoolIcon from '@material-ui/icons/Pool';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import CounterTriathlonButtonsProvider from "../../CounterTriathlonButtons";

const Counter = ({ workoutGroup, groupID, workoutPartID, config, type }) => {
    const [value, setValue] = useState(0)
    const workoutPath = `/workout/${workoutGroup}/${groupID}/${workoutPartID}`

    const percentage = config.Limit ? (value / config.Limit) * 100 : 0
    const exportValue = v => setValue(v)

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Progress
                        percentage={percentage}
                        type={type}
                    >
                        <DirectionsRunIcon fontSize={"small"} />/<DirectionsBikeIcon fontSize={"small"} /><br/>
                        <CounterTriathlonFeature
                            workoutPath={workoutPath}
                            exportValue={exportValue}
                            type={'RunBike'}
                        />
                    </Progress>
                </Grid>
                <Grid item xs={4}>
                    <Progress
                        percentage={percentage}
                        type={type}
                    >
                        <PoolIcon fontSize={"small"} /><br/>
                        <CounterTriathlonFeature
                            workoutPath={workoutPath}
                            exportValue={exportValue}
                            type={'Swim'}
                        />
                    </Progress>
                </Grid>
                <Grid item xs={4}>
                    <CounterTriathlonButtonsProvider disableRemove workoutPath={workoutPath} amount={1} type={'Run'}/>
                    <CounterTriathlonButtonsProvider disableRemove workoutPath={workoutPath} amount={1} type={'Bike'}/>
                    <CounterTriathlonButtonsProvider disableRemove workoutPath={workoutPath} amount={1} type={'Swim'}/>
                </Grid>
            </Grid>
        </>
    );
}

Counter.propTypes = {}

export default Counter;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import PoolIcon from '@material-ui/icons/Pool';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

import db, {dbTime} from '../../firebaseconfig'
import CounterButtons from './CounterButtons';
import {useName} from "../../GroupsContext";

const CounterButtonsProvider = ({ workoutGroup, workoutPath, amount, type, disableRemove = false }) => {
    const { name } = useName()
    const workout = db.ref(`${workoutPath}/counterTriathlon`)

    const [startTime, setStartTime] = useState(0)
    const timer = db.ref(`/timer/${workoutGroup}`)

    useEffect(() => {
        timer.limitToLast(1).on('value', (snapshot) => {
            const data = snapshot.val()
            if (data) {
                const keys = Object.keys(data.timer)
                setStartTime(data.timer[keys[keys.length - 1]].startTime)
            }
        })
    }, [])

    const addIterations = (count) => {
        workout.push({
            Count: amount,
            Name: name,
            Type: type,
            StartTime: startTime,
            Time: dbTime
        })
    }

    const removeIteration = () => {
        let removedItems = amount;
        const workout = db.ref(`${workoutPath}/counterTriathlon`)
            .orderByChild('Name')
            .equalTo(name)
            .get().then(snap => {
                if (snap.val()) {
                    let entries = Object.entries(snap.val())
                    const filteredEntries = entries.filter(item => {
                        return item[1].Type === type
                    })

                    for (let i = 0; i < amount; i++) {
                        if (filteredEntries[i] && removedItems > 0) {
                            if (parseInt(filteredEntries[i][1].Count, 10) < removedItems) {
                                db.ref(`${workoutPath}/counterTriathlon/${filteredEntries[i][0]}`)
                                    .remove()
                                removedItems = parseInt(removedItems, 10) - parseInt(filteredEntries[i][1].Count, 10)
                            } else {
                                const newValue = parseInt(filteredEntries[i][1].Count, 10) - parseInt(removedItems, 10);
                                if (newValue !== 0) {
                                    db.ref(`${workoutPath}/counterTriathlon/${filteredEntries[i][0]}`)
                                        .update({
                                            Count: parseInt(filteredEntries[i][1].Count, 10) - parseInt(removedItems, 10)
                                        })
                                } else {
                                    db.ref(`${workoutPath}/counterTriathlon/${filteredEntries[i][0]}`)
                                        .remove()
                                }
                                removedItems = parseInt(removedItems, 10) - parseInt(filteredEntries[i][1].Count, 10)
                            }

                        }
                    }
                }
            })
    }

    const icon = () => {
        switch (type) {
            case 'Bike': return <DirectionsBikeIcon fontSize={"small"} />
            case 'Swim': return <PoolIcon fontSize={"small"} />
            case 'Run': return <DirectionsRunIcon fontSize={"small"} />
        }
    }

    return (
        <>
            <CounterButtons
                addIterations={addIterations}
                removeIteration={removeIteration}
                amount={amount}
                icon={icon()}
                disableRemove={disableRemove}
            />
        </>
    );
}

CounterButtonsProvider.propTypes = {}

export default CounterButtonsProvider;

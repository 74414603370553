import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import db from "../../firebaseconfig";
import {useWorkoutConfig} from "../../GroupsContext";
import WorkoutPartProvider from "../WorkoutPart";
import Workout from "./Workout";

const WorkoutProvider = ({ workoutGroup, groupID }) => {

    const [groupName, setGroupName] = useState('')
    const workoutConfig = useWorkoutConfig()

    useEffect(() => {
        const dbGroups = db.ref(`groups/${workoutGroup}/${groupID}`);
        if (dbGroups) {
            const listener = dbGroups.on('value', item => {
                const data = item.val()
                setGroupName(data?.Name || '')
            })

            return () => dbGroups.off('value', listener);
        }
    });

    return (
        <>
            <Workout
                workoutGroup={workoutGroup}
                groupID={groupID}
                groupName={groupName}
                workoutConfig={workoutConfig}
            />
        </>
    );
}

WorkoutProvider.propTypes = {}

export default WorkoutProvider;

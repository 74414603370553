import React from 'react';
import PropTypes from 'prop-types';
import {Button, Grid} from "@material-ui/core";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import DeleteIcon from '@material-ui/icons/Delete';
import db, {dbTime} from '../../firebaseconfig'
import moment from 'moment'
import {useName} from "../../GroupsContext";

const TimerControlls = ({ controllsPath, isPlaying, time, startTime, latenz }) => {
    const controlls = db.ref(`${controllsPath}/timer`)
    const { name } = useName()

    const handleTimerClick = () => {
        controlls.push({
            time: startTime > 0
                ? moment(moment().diff(moment(startTime, 'x'))).add(time).add(latenz, 'ms').utc().format('x')
                : time,
            startTime: !isPlaying ? dbTime : 0,
            status: isPlaying ? 'stop' : 'start',
            name: name
        })
    }

    const handleTimerRightClick = e => {
        e.preventDefault()
        controlls.push({
            time: -10000,
            startTime: dbTime,
            status: 'start',
            name: name
        })
    }

    const handleResetClick = () => {
        controlls.push({
            time: 0,
            startTime: 0,
            status: 'stop'
        })
    }

    return (
        <Grid container>
            <Grid item xs={6}>
                <Button fullWidth variant={"outlined"} color={"primary"} onClick={handleTimerClick}
                        onContextMenu={handleTimerRightClick}>
                    {
                        isPlaying ? <PauseIcon/> : <PlayArrowIcon/>
                    }
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button fullWidth variant={"outlined"} color={"secondary"} onClick={handleResetClick}>
                    <DeleteIcon/>
                </Button>
            </Grid>
        </Grid>
    );
}

TimerControlls.propTypes = {}

export default TimerControlls;

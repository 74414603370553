import React from 'react';
import PropTypes from 'prop-types';
import {Button, Grid} from "@material-ui/core";
import {useStyles} from "./styles";

const CounterButtons = ({addIterations, removeIteration, amount}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Button
                    variant="outlined"
                    size="large"
                    color={'secondary'}
                    onClick={removeIteration}
                    className={classes.button}
                    fullWidth
                >
                    - {amount}
                </Button>
            </Grid>

            <Grid item xs={8}>
                <Button
                    variant="contained"
                    size="large"
                    color={'primary'}
                    onClick={addIterations}
                    className={classes.button}
                    fullWidth
                >
                    + {amount}
                </Button>
            </Grid>
        </Grid>
    );
}

CounterButtons.propTypes = {}

export default CounterButtons;

import { makeStyles } from '@material-ui/core/styles';

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles(theme => ({
    button: {
        padding: theme.spacing(1),
        fontSize: 20
    },
}));

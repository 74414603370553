import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {navigate} from 'hookrouter';
import AppBarMenu from "./AppBarMenu";
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import SideMenu from "./SideMenu";
import EditName from "../../features/EditName";
import EditGroupName from "../../features/EditGroupName";
import {useName} from "../../GroupsContext";

const LayoutHeader = ({ classes, workoutGroup, groupID }) => {
    const [open, setOpen] = useState(false)
    const [mobileOpen, setMobileOpen] = useState(false);

    const { name } = useName();
    const anchorRef = useRef(null)

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap onClick={() => navigate.push('/')}>
                        Workout-Tracker
                    </Typography>
                    <Typography variant="body1" noWrap>
                        {name}
                    </Typography>
                    <AppBarMenu name={name} workoutGroup={workoutGroup} groupID={groupID} />
                </Toolbar>
            </AppBar>

            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        container={window !== undefined ? () => window.document.body : undefined}
                        variant="temporary"
                        anchor={'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <SideMenu
                            classes={classes}
                            setMobileOpen={setMobileOpen}
                            workoutGroup={workoutGroup}
                        />
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <SideMenu
                            classes={classes}
                            setMobileOpen={setMobileOpen}
                            workoutGroup={workoutGroup}
                        />
                    </Drawer>
                </Hidden>
            </nav>
        </>
    );
}

LayoutHeader.propTypes = {
    classes: PropTypes.object
}

export default LayoutHeader

import React from 'react';
import PropTypes from 'prop-types';
import Workout from "../features/Workout";
import {Button, ButtonGroup, Grid, IconButton, Paper, Typography} from "@material-ui/core";
import Timer from "../features/Timer";
import Dashboard from "../features/Dashboard";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import {useDashboardType} from "../GroupsContext";

const DashboardPage = ({workoutGroup }) => {
    const {dashboardType, setDashboardType} = useDashboardType()
    console.log(dashboardType)
    return (
        <Grid container justify={"flex-end"} spacing={2}>
            <Grid item>
                <ButtonGroup variant={"contained"} color="primary" aria-label="contained primary button group" style={{marginLeft: 'auto'}}>
                    <Button variant={dashboardType !== 'circle' ? 'outlined' : null} onClick={() => setDashboardType('circle')}>
                        <DonutLargeIcon />
                    </Button>
                    <Button variant={dashboardType !== 'bar' ? 'outlined' : null} onClick={() => setDashboardType('bar')}>
                        <ClearAllIcon />
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
                <Paper style={{padding: 30}}>
                    <Typography variant={'h1'} align={"center"}>
                        <Timer
                            display
                            path={`/timer/${workoutGroup}`}
                        />
                    </Typography>
                    <Timer
                        controlls
                        path={`/timer/${workoutGroup}`}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Dashboard workoutGroup={workoutGroup} />
            </Grid>
        </Grid>
    );
}

DashboardPage.propTypes = {}

export default DashboardPage;

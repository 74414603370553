import React from 'react';
import PropTypes from 'prop-types';
import PokerCardsFeature from "../../PokerCards";
import CounterButtonsProvider from "../../CounterButtons";
import {Grid, Typography} from "@material-ui/core";

const Counter = ({ workoutGroup, groupID, workoutPartID, config }) => {
    const workoutPath = `/workout/${workoutGroup}/${groupID}/${workoutPartID}`
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h1'}>
                        <PokerCardsFeature
                            controlls
                            gamePath={workoutPath}
                            config={config}
                        />
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

Counter.propTypes = {}

export default Counter;

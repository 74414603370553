import { createMuiTheme } from '@material-ui/core';

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
    typography: {
        h1: {
            fontSize: 32,
            marginBottom: defaultTheme.spacing(2)
        },
        h2: {
            fontSize: 28,
            marginBottom: defaultTheme.spacing(1)
        },
        h3: {
            fontSize: 24,
            marginBottom: defaultTheme.spacing(1)
        },
        h4: {
            fontSize: 22,
            marginBottom: defaultTheme.spacing(1)
        },
        h5: {
            fontSize: 20,
            marginBottom: defaultTheme.spacing(1)
        },
        h6: {
            fontSize: 18,
            marginBottom: defaultTheme.spacing(1)
        },
    },
    palette: {
        primary: {
          main: '#144D93'
        },
        secondary: {
            main: '#E0362A'
        }
    }
});

export default theme;

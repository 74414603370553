import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TimerFeature from '../../Timer'
import {Typography} from "@material-ui/core";
import Progress from "./Progress";

const Timer = ({workoutGroup, groupID, workoutPartID, config, type}) => {
    const [value, setValue] = useState(0)
    const timerPath = `/workout/${workoutGroup}/${groupID}/${workoutPartID}`

    const percentage = Math.round(config.Limit ? (value / config.Limit) * 100 : 0, 10)
    const exportValue = v => setValue(v)

    return (
        <>
            <Progress
                percentage={percentage}
                type={type}
            >
                <TimerFeature
                    display
                    path={timerPath}
                    exportValue={exportValue}
                />
            </Progress>
        </>
    );
}

Timer.propTypes = {}

export default Timer;

import React from 'react';
import PropTypes from 'prop-types';
import {FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

const EditItem = ({ config, workoutGroup, updateTitle, updateSubtitle, updateLimit, updateType, deleteWorkout }) => {

  return (
    <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
            <TextField
                value={config.Title}
                label={'Titel'}
                onChange={e => updateTitle(config.ID, e.target.value)}
                fullWidth
            />
        </Grid>
        <Grid item xs={6} md={3}>
            <TextField
                value={config.Subtitle}
                label={'Beschreibung'}
                onChange={e => updateSubtitle(config.ID, e.target.value)}
                fullWidth
            />
        </Grid>
        <Grid item xs={3} md={2}>
            <TextField
                value={config.Limit}
                label={'Limit'}
                onChange={e => updateLimit(config.ID, e.target.value)}
                fullWidth
            />
        </Grid>
        <Grid item xs={6} md={3}>
            <FormControl fullWidth>
                <InputLabel>Typ</InputLabel>
                <Select
                    value={config.Type}
                    label={'Typ'}
                    onChange={e => updateType(config.ID, e.target.value)}
                    fullWidth
                >
                    <MenuItem value={'Counter'}>Counter</MenuItem>
                    <MenuItem value={'CounterCentralTimer'}>CounterCentralTimer</MenuItem>
                    <MenuItem value={'CounterTimer'}>CounterTimer</MenuItem>
                    <MenuItem value={'Timer'}>Timer</MenuItem>
                    <MenuItem value={'TimerGroup'}>TimerGroup</MenuItem>
                    <MenuItem value={'PokerCards'}>PokerCards</MenuItem>
                    <MenuItem value={'CounterTriathlon'}>Triathlon</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={3} md={1}>
            <IconButton onClick={() => deleteWorkout(config.ID)}>
                <DeleteIcon />
            </IconButton>
        </Grid>
    </Grid>
  );
}

EditItem.propTypes = {}

export default EditItem;

import React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Button} from "@material-ui/core";
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListSubheader from '@material-ui/core/ListSubheader';
import { navigate } from 'hookrouter';
import moment from 'moment';

import { useGroups } from "../../GroupsContext";
import AddGroupProvider from "../../features/AddGroup";
import {laufergebnisse, zoomLink} from "../../config";

const SideMenu = ({ classes, setMobileOpen, workoutGroup }) => {
    const groups = useGroups()
  return (
      <div>
          <div className={classes.toolbar} />
          <Divider />
          <List subheader={<ListSubheader>Links</ListSubheader>}>
              <ListItem button onClick={() => {
                  navigate('/')
              }}>
                  <ListItemIcon>{<DashboardIcon />}</ListItemIcon>
                  <ListItemText primary={'Übersicht'} />
              </ListItem>
              <ListItem button onClick={() => {
                  window.open(zoomLink, '_blank');
              }}>
                  <ListItemIcon>{<OndemandVideoIcon />}</ListItemIcon>
                  <ListItemText primary={'Zoom'} />
              </ListItem>
              <ListItem button onClick={() => {
                  window.open(laufergebnisse, '_blank');
              }}>
                  <ListItemIcon>{<DirectionsRunIcon />}</ListItemIcon>
                  <ListItemText primary={'Laufergebnisse'} />
              </ListItem>
          </List>
          <Divider />
          <List subheader={<ListSubheader>Gruppen</ListSubheader>}>
              {groups.map((text, index) => (
                  <ListItem button key={text.key} onClick={() => {
                      navigate(`/${moment().format('YYYYMMDD')}/${text.key}`);
                      setMobileOpen(false);
                  }}>
                      <ListItemIcon>{<FitnessCenterIcon />}</ListItemIcon>
                      <ListItemText primary={text.value.Name} />
                  </ListItem>
              ))}
          </List>
          <Divider />
          <List subheader={<ListSubheader>Neu hinzufügen</ListSubheader>}>
              <ListItem style={{flexDirection: 'column'}}>
                <AddGroupProvider workoutGroup={workoutGroup} />
              </ListItem>
          </List>
      </div>
  );
}

SideMenu.propTypes = {}

export default SideMenu;

import React from 'react';
import PropTypes from 'prop-types';
import {Button, Grid} from "@material-ui/core";
import {useStyles} from "./styles";

const CounterButtons = ({addIterations, removeIteration, amount, icon, disableRemove}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            {!disableRemove && (
                <Grid item xs={4}>
                    <Button
                        variant="outlined"
                        size={disableRemove ? 'small' : 'large'}
                        color={'secondary'}
                        onClick={removeIteration}
                        className={classes.button}
                        fullWidth
                    >
                        - {icon}
                    </Button>
                </Grid>
            )}

            <Grid item xs={disableRemove ? 12 : 8}>
                <Button
                    variant="contained"
                    size={disableRemove ? 'small' : 'large'}
                    color={'primary'}
                    onClick={addIterations}
                    className={classes.button}
                    fullWidth
                >
                    {disableRemove ? '' : '+'} {icon}
                </Button>
            </Grid>
        </Grid>
    );
}

CounterButtons.propTypes = {}

export default CounterButtons;

import React, { useState, useEffect } from 'react'
import moment from 'moment';
import Layout from "./layout";
import Snackbar from "./features/Snackbar";
import { useRoutes, setBasepath, usePath, navigate } from 'hookrouter';
import GroupsContextProvider from "./GroupsContext";
import Workout from "./pages/Workout";
import DashboardPage from "./pages/Dashboard";

const RouteResolver = () => {

}

const App = () => {

    const path = usePath();

    const defaultWorkoutGroup = moment().format('YYYYMMDD')

    const routes = {
        '/:workoutGroup/:groupID': ({ workoutGroup, groupID }) => <Workout workoutGroup={workoutGroup} groupID={groupID} />,
        '/:workoutGroup': ({ workoutGroup }) => <DashboardPage workoutGroup={workoutGroup} />,
        '/': () => navigate(`/${defaultWorkoutGroup}`)
        // '/zuege/belegung/:trainConnectionID/': ({ trainConnectionID }) => <TrainOccupancy trainConnectionID={trainConnectionID} />,
        // '/zuege/auswahl/:trainConnectionID/': ({ trainConnectionID }) => <TrainDetails trainConnectionID={trainConnectionID} />,
        // '/zuege/auswahl/': () => <TrainSelect type={'view'} />,
        // '/zuege/verwaltung/': () => <TrainSelect type={'edit'} />,
        // '/zuege/': () => navigate('/zuege/auswahl/'),
        // '/checkin/': () => <CheckIn />,
        // '/suche/': () => <TicketSearch />,
        // '/login/': () => <Login />,
        // '/logout/': () => <Logout />
    }

    const content = useRoutes(routes);

    return (
        <GroupsContextProvider workoutGroup={content?.props.workoutGroup || defaultWorkoutGroup}>
            <Layout workoutGroup={content?.props.workoutGroup || defaultWorkoutGroup} groupID={content?.props.groupID}>
                <Snackbar>
                    {content}
                </Snackbar>
            </Layout>
        </GroupsContextProvider>
    )
}

export default App;

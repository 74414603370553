import React from 'react';
import PropTypes from 'prop-types';
import TimerFeature from '../../Timer'
import {Grid, Typography} from "@material-ui/core";
import {useName} from "../../../GroupsContext";

const TimerGroup = ({workoutGroup, groupID, workoutPartID}) => {
    const { name } = useName()
    const timerPath = `/workout/${workoutGroup}/${groupID}/${workoutPartID}`
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant={'subtitle1'} component={'div'} align={"center"}>
                    Meine Zeit
                </Typography>
                <Typography variant={'h3'} component={'div'} align={"center"}>
                    <TimerFeature
                        display
                        path={`${timerPath}/timer/group/${name}`}
                    />
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TimerFeature
                    otherGroups
                    groupTimer
                    path={timerPath}
                />
            </Grid>
            <Grid item xs={6}>
                <Typography variant={'subtitle1'} component={'div'} align={"right"}>
                    Gesamt
                </Typography>
                <Typography variant={'h1'} align={"right"}>
                    <TimerFeature
                        display
                        groupTimer
                        path={timerPath}
                    />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TimerFeature
                    controlls
                    path={`${timerPath}/timer/group/${name}`}
                />
            </Grid>
        </Grid>
    );
}

TimerGroup.propTypes = {}

export default TimerGroup;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import db from '../../firebaseconfig'
import moment from "moment";

const CounterTriathlonListProvider = ({ workoutGroup, workoutPath, exportValue, type }) => {
    const [list, setList] = useState([]);
    const workout = db.ref(`${workoutPath}/counterTriathlon`)

    const convertData = data => {
        const tmpList = []
        if (data) {
            let prevRound = 0
            let prevRoundTime = 0
            Object.keys(data).forEach((key, index) => {
                if (index === 0) {
                    prevRoundTime = data[key].StartTime
                }
                if (data[key].Count) {
                    let thisRound = data[key].Time - prevRoundTime
                    tmpList.push({
                        ...data[key],
                        RountTime: thisRound
                    })
                    prevRoundTime = data[key].Time
                    prevRound = thisRound
                }
            })
        }

        if (JSON.stringify(tmpList) !== JSON.stringify(list)) {
            setList(tmpList)
        }
    }

    workout.on('value', (snapshot) => {
        convertData(snapshot.val())
    });


    return list.map((item, index) => (
        <div>
            {`Runde ${index + 1}: ${moment(item.RountTime, 'x').format('mm:ss')} (${item.Type})`}
        </div>
    ))
}

CounterTriathlonListProvider.propTypes = {}

export default CounterTriathlonListProvider;
